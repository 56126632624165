<template>
  <ul class="hc-list" :style="{fontSize, fontWeight, gap}">
    <li v-for="(item,index) in items" :key="item.id">
      <slot :li="item" :name="'li-content-' + index"/>
      <slot :item="item"/>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: Array,
    fontSize: String,
    fontWeight: String,
    gap: String
  }
}
</script>

<style scoped>
.hc-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
  list-style: unset;
}
</style>
